import type {FeaturedAgentTrackingData} from "@/modules/propertyCard/property.index";
import type {SearchTrackingType} from "@/pages/search/search.types";
import {EnquiryClickTracking, EnquiryLeadTracking, UserEnquiry} from "@/modules/enquiry/enquiry.types";
import {HeroBannerTrackingType} from "@/modules/gallery/gallery.hero-banner";

type availableTrackingKeys = 'google' | 'facebook' | 'tiktok' | 'line'
type availableTrackingKeysArray = {
  enquiry: availableTrackingKeys[],
  click: availableTrackingKeys[],
}

type TrackingDataType =
  FeaturedAgentTrackingData |
  EnquiryLeadTracking |
  EnquiryClickTracking |
  SearchTrackingType |
  HeroBannerTrackingType |
  null

export const trackingKeys: availableTrackingKeysArray = {
  enquiry: ['google', 'facebook', 'tiktok', 'line'],
  click: ['google'],
}

export function gtag() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments)
}

export const tracking = (
  name: string,
  trackingData: TrackingDataType,
  trackKeys: availableTrackingKeys[],
  userTracking: UserEnquiry | {} = {}
): void => {

  if (window.location.search.includes('test')) {
    console.log('test environment')
    return;
  }

  // google
  if (trackKeys.includes("google")) {
    googleTracking(name, trackingData, userTracking);
  }

  // facebook
  if (trackKeys.includes("facebook")) {
    facebookTracking(trackingData);
  }

  // tiktok
  if (trackKeys.includes("tiktok")) {
    tiktokTracking(trackingData);
  }

  // line
  if (trackKeys.includes("line")) {
    lineTracking();
  }
}

const googleTracking = (
  gtagEventName: string,
  trackingData: TrackingDataType,
  userData: UserEnquiry | {}
) => {
  // Google Ads: Track all conversions:
  if (gtagEventName.includes("_lead")) {
    // @ts-ignore
    gtag('set', 'user_data', userData);
    // @ts-ignore
    gtag('event', 'conversion', {'send_to': 'AW-11190999410/gxKsCNC_8bsYEPKypNgp'});
  }
  // @ts-ignore
  gtag('event', gtagEventName, trackingData ?? {});
}

const facebookTracking = (trackingData: any) => {
  // Facebook Pixel
  // @ts-ignore
  if (typeof (fbq) !== "undefined") {
    // @ts-ignore
    fbq('track', 'Lead', trackingData ?? {});
  }
}

const tiktokTracking = (trackingData: any) => {
  // Tiktok Pixel
  // @ts-ignore
  if (typeof (ttq) !== "undefined") {
    // @ts-ignore
    ttq.track("SubmitForm", {
      contents: [
        {
          content_id: trackingData.ref_id,
          content_type: trackingData.ref_type,
          content_name: trackingData.ref_type + ' ' + trackingData.ref_id,
        },
      ],
    });
  }
}

export const facebookCompleteRegistration = () => {
  // Facebook Pixel
  // @ts-ignore
  if (typeof (fbq) !== "undefined") {
    // @ts-ignore
    fbq('track', 'CompleteRegistration');
  }
}

const lineTracking = () => {
  // Line Pixel
  // @ts-ignore
  if (typeof (_lt) !== "undefined") {
    // @ts-ignore
    _lt('send', 'cv', {
      type: 'LineOfficialNestopa'
    }, ['79ccad87-40a3-476b-bd32-25b5000c807f']);
  }
}

